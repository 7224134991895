<template>
  <div id="updatePassword">
    <header-bar title="修改密码" />
    <ul>
      <li>
        <p>{{ params.password ? '请输入原密码': '' }}</p>
        <input v-model="params.password" type="password" placeholder="请输入原密码">
      </li>
      <li>
        <p>{{ params.new_password ? '密码为8-15位，包含大小写字母、数字和特殊符号': '' }}</p>
        <input v-model="params.new_password" type="password" placeholder="密码为8-15位，包含大小写字母、数字和特殊符号">
      </li>
      <li>
        <p>{{ params.confirmPassword ? '请确认新密码': '' }}</p>
        <input v-model="params.confirmPassword" type="password" placeholder="请确认新密码">
      </li>
    </ul>

    <div class="button" :class="{disabled: isDisabled}" @click="confirm">确认修改</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HeaderBar from '../components/HeaderBar/index';

export default {
  name: 'UpdatePassword',
  components: { HeaderBar },
  props: [],
  data() {
    return {
      params: {
        password: '',
        confirmPassword: '',
        new_password: ''
      }
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
    isDisabled() {
      return !this.params.password || !this.params.confirmPassword || !this.params.new_password;
    }
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    confirm() {
      if (this.isDisabled) return;

      // 新密码验证规则
      const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/;
      if (!reg.test(this.params.new_password)) {
        this.$toast('密码格式不正确，请输入8-15位，包含大小写字母、数字特殊符号的组合');
        return;
      }

      if (this.params.new_password !== this.params.confirmPassword) {
        this.$toast('两次密码输入不一致');
        return;
      }

      const { password, new_password } = this.params;
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      });

      this.http.$post(this.$api.updatePassword, {
        password,
        new_password
      }).then(res => {
        this.$toast('修改成功请重新登录');
        setTimeout(() => {
          this.$store.dispatch('userInfo/logout');
          this.$router.push({ name: 'login' });
        }, 2000);
      }).finally(() => {
        this.$toast.clear();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
#updatePassword {
  ul {
    margin-bottom: 24px;
    li {
      margin-bottom: 2px;
      background: #fff;
      padding: 16px 32px 6px;
      height: 134px;
      p {
        height: 34px;
        line-height: 34px;
        font-size: 24px;
        color: $color-grey-light;
      }
      input {
        padding: 18px 0;
        height: 40px;
        line-height: 40px;
        display: block;
        width: 100%;
      }
    }
  }
  .button {
    width: 686px;
    height: 80px;
    line-height: 80px;
    font-size: 32px;
    color: #fff;
    background: $color-primary;
    text-align: center;
    margin: 0 auto;
    border-radius: 4px;
    &.disabled {
      background: rgba($color-primary, .2);
    }
  }
}
</style>